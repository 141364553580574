export const moduleAccessMatrix = {};
    moduleAccessMatrix['Unmapped CPN'] = {
        'R': ['/pages/cpn/unmapped-cpn', '/pages/cpn/unmapped-cpn/edit/mapping/'],
        'W': ['/pages/cpn/unmapped-cpn', '/pages/map-cpn-msn/', '/pages/cpn/unmapped-cpn/edit/mapping/']
    };

    moduleAccessMatrix['CPN MSN Mapping'] = {
        'R': ['/pages/cpn/unmapped-cpn', '/pages/cpn/unmapped-cpn/edit/mapping/'],
        'W': ['/pages/cpn/unmapped-cpn', '/pages/map-cpn-msn/', '/pages/cpn/unmapped-cpn/edit/mapping/']
    };

    moduleAccessMatrix['Search MSN'] = {
        'R': ['/pages/msn/search', '/pages/msn/list/all', '/pages/msn/list/ALL', '/pages/msn/list/GLOBAL', '/pages/msn/list/JUNK', '/pages/msn/list/MTO', '/pages/msn/list/ABFRL', '/pages/msn/detail/', '/pages/msn/audit-view-all/'],
        'W': ['/pages/msn/search', '/pages/msn/list/all', '/pages/msn/list/ALL', '/pages/msn/list/GLOBAL', '/pages/msn/list/JUNK', '/pages/msn/list/MTO', '/pages/msn/list/ABFRL','/pages/msn/detail/', '/pages/msn/audit-view-all/', '/pages/create/edit-msn','/pages/create/clone-msn']
    };

    moduleAccessMatrix['Create MSN'] = {
        'R': [],
        'W': ['/pages/create/create-msn']
    };

    moduleAccessMatrix['Download Report'] = {
        'R': ['/pages/report'],
        'W': ['/pages/report']
    };

    moduleAccessMatrix['Bulk Product Operation'] = {
        'R': ['/pages/products/bulk-new-upload'],
        'W': ['/pages/products/bulk-new-upload']
    };

    moduleAccessMatrix['Verify Product'] = {
        'R': ['/pages/products/verify/verifyproduct', '/pages/products/verify/view-failed/'],
        'W': ['/pages/products/verify/verifyproduct', '/pages/products/verify/view-failed/',
         '/pages/products/verify/view-succeeded/','/pages/products/verify/view-warning/', '/pages/products/verify/product-detail/']
    };


    moduleAccessMatrix['Product Download'] = {
        'R': ['/pages/products/download'],
        'W': ['/pages/products/download']
    };


    moduleAccessMatrix['Create Brand'] = {
        'R': [],
        'W': ['/pages/brand/create']
    };


    moduleAccessMatrix['Update Brand'] = {
        'R': ['/pages/brand/view/view', '/pages/brand/view/detail/'],
        'W': ['/pages/brand/view/view', '/pages/brand/view/detail/', '/pages/brand/view/edit/', '/pages/brand/view/view-catalog-docs/']
    };


    moduleAccessMatrix['Create Category'] = {
        'R': [],
        'W': ['/pages/category/create']
    };


    moduleAccessMatrix['View Category'] = {
        'R': ['/pages/category/update/search', '/pages/category/update/detail/'],
        'W': ['/pages/category/update/search', '/pages/category/update/detail/', '/pages/category/update/update/']
    };

    moduleAccessMatrix['Update Category'] = {
        'R': ['/pages/category/update/search', '/pages/category/update/detail/'],
        'W': ['/pages/category/update/search', '/pages/category/update/detail/', '/pages/category/update/update/']
    };

    moduleAccessMatrix['Supplier Mapping'] = {
        'R': [],
        'W': ['/pages/supplier/mapping']
    };

    moduleAccessMatrix['Supplier Download'] = {
        'R': ['/pages/supplier/download'],
        'W': ['/pages/supplier/download']
    };

    moduleAccessMatrix['Supplier Approval'] = {
        'R': ['/pages/supplier/approval'],
        'W': ['/pages/supplier/approval']
    };

    moduleAccessMatrix['Supplier Demapping'] = {
        'R': [],
        'W': ['/pages/supplier/demapping']
    };

    moduleAccessMatrix['Commercial Category Bulk Update'] = {
        'R': ['/pages/supplier/commercial'],
        'W': ['/pages/supplier/commercial']
    };
    
    moduleAccessMatrix['Offer Price'] = {
        'R': [],
        'W': ['/pages/offer-price/offer-price']
    };
    
    moduleAccessMatrix['Template'] = {
        'R': ['/pages/template/view'],
        'W': ['/pages/template/view']
    };
    
    moduleAccessMatrix['Static Field'] = {
        'R': ['/pages/static-field'],
        'W': ['/pages/static-field']
    };
    
    moduleAccessMatrix['LPA'] = {
        'R': ['/pages/lpa'],
        'W': ['/pages/lpa']
    };

    moduleAccessMatrix['verify Mapping'] = {
        'R': ['/pages/cpn/unmapped-cpn/verifycpn'],
        'W': ['/pages/cpn/unmapped-cpn/verifycpn']
    };
    moduleAccessMatrix['Create Group'] = {
        'R': ['/pages/catalog-grouping/create'],
        'W': ['/pages/catalog-grouping/create']
    };
    moduleAccessMatrix['View Group'] = {
        'R': ['/pages/catalog-grouping/view'],
        'W': ['/pages/catalog-grouping/view']
    };
    
