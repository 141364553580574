import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdateProductsComponent } from './updateproducts.component';

const routes: Routes = [
  {
    path: '',
    component: UpdateProductsComponent,
    children: [
      {path:'', redirectTo:'bulk-update',pathMatch: 'full'},
       { path: 'bulk-update', loadChildren: 'src/app/pages/updateproducts/bulk-update/bulk-update.module#BulkUpdateModule' },
      { path: 'map-supplier-product', loadChildren: 'src/app/pages/updateproducts/map-supplier-product/mapsupplierproduct.module#MapSupplierProductModule' },
      { path: 'activestatus', loadChildren: 'src/app/pages/updateproducts/active-status/activestatus.module#ActiveStatusModule' }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdateProductsRoutingModule { }