
import { BulkUpdateComponent } from './bulk-update.component';
import { BulkUpdateRoutingModule } from './bulk-update-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload/upload.component';
import { UpdateProdResponseComponent } from './update-productresponse/update-prod-response.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule, MatInputModule,MatNativeDateModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    BulkUpdateRoutingModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule
  ],
  declarations: [BulkUpdateComponent,UploadComponent,UpdateProdResponseComponent]
})
export class BulkUpdateModule { }
