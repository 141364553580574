import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from '../../../../global-state.service';
import { MappingService } from '../../../cpn-mapping.service';

@Component({
  selector: 'update-product-response',
  templateUrl: './update-prod-response.component.html',
  styleUrls: ['./update-prod-response.component.scss']
})
export class UpdateProdResponseComponent implements OnInit {

  responseSubscribe: any;
  response: any = {};
  failureCaseResponse: any;
  failureKeys: any = {}
  constructor(private _state: GlobalStateService, private _service: MappingService) { }

  ngOnInit() {
    /*  this.responseSubscribe = this._state._dialogBoxEventStream.subscribe(resp => {
        this.response = resp['result'];
        this.failureCaseResponse = this.response['failureDetails'];
        this.failureKeys = Object.keys(this.failureCaseResponse);
      });
      */

    this.response = this._service.getResponseData();
    this.failureCaseResponse = this.response['failureDetails'];
    this.failureKeys = Object.keys(this.failureCaseResponse);

    /*
        this.failureKeys = Object.keys({
          "Grouping failure": [
            "MSN2VHMJ5PJZO7"
          ]
        });
        this.failureCaseResponse = {
          "Grouping failure": [
            "MSN2VHMJ5PJZO7"
          ]
        };
        this.response = {
          "downloadFileName": "Product-name.csv",
          "uploadedBy": "sandeep",
          "uploadedFor": "test",
          "numFailed": 0,
          "numSucceeded": 1,
          "failureDetails": {
            "Grouping failure": [
              "MSN2VHMJ5PJZO7"
            ]
          }
        }
    */

  }

  getKey(key) {
    return this.failureCaseResponse[key];
  }

  ngOnDestroy() {
    if (this.responseSubscribe >= 0) this.responseSubscribe.unsubscribe();
    this._service.clearResponeData();
  }

}
