import { Hero } from './hero';

export const HEROES:Hero[] = [
    {id:1, name:'hero1'},
    {id:2, name:'hero2'},
    {id:3, name:'hero3'},
    {id:4, name:'hero4'},
    {id:5, name:'hero5'}
];


