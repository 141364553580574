/**
 * Created by nitish ranjan on 09/10/18.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class GlobalStateService {

  private _data = new BehaviorSubject<any[]>([]);
  public _dataStream = this._data.asObservable();

  private _cpnMapping = new Subject<any>();
  public _cpnMappingStream = this._cpnMapping.asObservable();

  private _msnData = new BehaviorSubject<any[]>([]);
  public _msnDataStream = this._msnData.asObservable();

  private _modifiedData = new Subject<any>();
  public _modifiedDataStream = this._modifiedData.asObservable();

  private _dialogBoxEvent = new Subject<any>();
  public _dialogBoxEventStream = this._dialogBoxEvent.asObservable()

  private _cpnCountEvent = new Subject<any>();
  public _cpnCountEventStream = this._cpnCountEvent.asObservable()

  private _teamWise = new Subject<any>();
  public _teamWiseStream = this._teamWise.asObservable();

  private _refreshCpnData = new Subject<any>();
  public _refreshCpnDataStream = this._refreshCpnData.asObservable();

  private _categoryDisplay = new BehaviorSubject<any[]>([]);
  public _categoryDisplayStream = this._categoryDisplay.asObservable();

  private _templateEvent = new Subject<any>();
  public _templateStream = this._templateEvent.asObservable()

  private _templateRefreshEvent = new Subject<any>();
  public _templateRefreshStream = this._templateRefreshEvent.asObservable()

  private _mappedData = new BehaviorSubject<any[]>([]);
  public _mappedDataStream = this._mappedData.asObservable();

  private _subscriptions: Map<string, Array<Function>> = new Map<string, Array<Function>>();

// catalog brand
  private _catalogDocumentViewRefreshEvent = new Subject<any>();
  public _catalogDocumentViewRefreshStream = this._catalogDocumentViewRefreshEvent.asObservable();

  constructor() {
    this._dataStream.subscribe((data) => this._onEvent(data));
  }

  notifyCpnDetailList(value) {
    this._data.next(value);
  }

  notifyMappedCpnDetails(value){
    this._mappedData.next(value)
  }
  notifyCpnMappingDetail(value) {
    this._cpnMapping.next(value);
  }

  notifyModifiedList(value) {
    this._modifiedData.next(value);
  }

  notifyMsnList(value) {
    this._msnData.next(value);
  }

  // dialogBoxEvent() {
  //   this._dialogBoxEvent.next(true);
  // }

  refreshCpnDataEvent() {
    this._refreshCpnData.next(true);
  }
  dialogBoxEvent(value) {
    this._dialogBoxEvent.next(value);
  }

  notifyCpnCountEvent(value) {
    this._cpnCountEvent.next(value);
  }

  notifiyTeamFilterChange(value) {
    this._teamWise.next(value);
  }

  notifyCategoryDisplay(value) {
    this._categoryDisplay.next(value);
  }

  templateEvent(value) {
    this._templateEvent.next(value);
  }

  templateRefreshEvent(value) {
    this._templateRefreshEvent.next(value);
  }

  setCategoryList(category) {
    localStorage.setItem('category', JSON.stringify(category));
  }

  getCategoryList() {
    return JSON.parse(localStorage.getItem('category'));
  }

  clearCategoryList() {
    localStorage.removeItem('category');
  }

  subscribe(event: string, callback: Function) {
    let subscribers = this._subscriptions.get(event) || [];
    subscribers.push(callback);

    this._subscriptions.set(event, subscribers);
    return subscribers.length - 1;
  }

  unsubscribe(event: string, index: number) {
    let subscribers = this._subscriptions.get(event) || [];
    if (subscribers.length - 1 >= index) {
      subscribers.splice(index, 1);
    }
    this._subscriptions.set(event, subscribers);
  }

  _onEvent(data: any) {
    let subscribers = this._subscriptions.get(data['event']) || [];

    subscribers.forEach((callback) => {
      callback.call(null, data['data']);
    });
  }



  catalogDocumentViewRefreshEvent() {
    this._catalogDocumentViewRefreshEvent.next(true);
  }
}
