import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../data.service';
import { params } from '../params';
import { urls } from '../urls';
import { FormControl, Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { HostListener } from '@angular/core';
import { AuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { HttpParams } from '@angular/common/http';
import { _MatSlideToggleMixinBase } from '@angular/material';
import { moduleAccessMatrix } from '../../theme/Utils/module-access-matrix';
import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: SocialUser;
  countryList=[];
  public email = new FormControl('', [Validators.required, Validators.email]);
  public password = new FormControl('', [Validators.required, Validators.minLength(1)]);
  invalid = false;
  public form: FormGroup;
  returnUrl: string;
  moduleList = [];
  userAllowAccessRoutingLink: any = new Set();

  constructor(private route: ActivatedRoute, private router: Router, private service: DataService, private authService: AuthService, private _msalService: MsalService) {
  

  
  }

  ngOnInit() {
  
    // this.authService.authState.subscribe((user) => {
    //   this.user = user;
    //   console.log(user);
    // });
   // this.service.destorySession();
    //  this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      if (x.email.includes('@moglix.com')) {
        this.userLogin(x.email);
      } else {
        this.service.showMessage('error', 'Please login with moglix google account');
      }
    });
  }

  microsoftSignIn(){
    // this._msalService.logout(); ⇐ Logout 
    this._msalService.loginPopup().subscribe((userData: AuthenticationResult) => {
        if(userData['accessToken']) {
          this.userLogin(userData.idTokenClaims['preferred_username'].toLowerCase());
        }
      });
  }


  signOut(): void {
    this.authService.signOut();
    // this._msalService.logout(); ⇐ Logout 
  }

  // getEmailErrorMessage() {
  //   return this.email.hasError('required') ? 'You must enter a value' :
  //     this.email.hasError('email') ? 'Not a valid email' :
  //       '';
  // }
  // getPasswordErrorMessage() {
  //   return this.password.hasError('required') ? 'You must enter a value' :
  //     this.password.hasError('email') ? 'Please enter password' :
  //       '';
  // }

  // onSubmit() {
  //   // send the parameters if login successfull rediect to page else remain on this page
  //   if (this.email.invalid || this.password.invalid) {
  //     this.invalid = true;
  //   } else {
  //     const url = params.prod_supp + urls.loginUser;
  //     const body = {
  //       'usermail': this.email.value,
  //       'password': this.password.value
  //     };
  //     this.service.callRestful('POST', url, body, null, null, null).subscribe(this.processLoginResult.bind(this));
  //   }
  // }



  userLogin(email) {
    // send the parameters if login successfull rediect to page else remain on this page
    const url = params.prod_supp + urls.catalogUserLogin;
    const body = {
      'username': email,
      'domainType': 'ECS'
    };
    // tslint:disable-next-line: comment-format
    //this.processLoginResult(true);
    //if(!this.service.getSession())
    this.service.callRestful('POST', url, body, null, null, null).subscribe(this.processLoginResult.bind(this));
    
  }

  // processLoginResult(data){
  //   this.service.offSpinner();
  //   this.service.showMessage("success",data.message);
  //     this.service.createSession("demouseremail", "data.user", "data.token");
  //     this.router.navigateByUrl('/pages');
  // }

  processLoginResult(data) {
    //   this.service.offSpinner();
    if (data.status) {
      this.invalid = false;
      this.service.createSession('1', data.username, data.token);
      this.getAccessModuleDetails(data.username);
      //  this.service.showMessage('success', data.message);
      this.service.createSession('1', data.username, data.token);
      //   this.router.navigateByUrl('/pages');
    } else {
      this.invalid = true;
      this.service.showMessage('error', data.message);
      this.service.offSpinner();
    }

    // this.service.createSession("demoUser", this.email);
    // this.router.navigateByUrl('/pages');
  }

  getAccessModuleDetails(email: any) {
    const url = params.user_management + urls.UserManagement.accessModuleList;
    const parameters = new HttpParams().set('appName', 'Catalog').set('userEmail', email).set('secretKey', params.user_management_secret_key);
    this.service.callRestful('GET', url, null, null, null, parameters, false).subscribe(this.processAccessModuleResult.bind(this));
  }

  processAccessModuleResult(data) {
    this.service.offSpinner();
    if (data['status'] === 'SUCCESS') {
      const modules = {};
      const moduleList = data['data']['CONFIG_LIST']['Enterprise'][0]['moduleList'];
      const countryListData = data['data']['COUNTRYS_LIST'];
      console.log(countryListData);
      
      countryListData.forEach((country: any) => {
        this.countryList.push(country);
        console.log(country);
      });

      if(null != this.countryList){
        this.service.setCountryList(this.countryList);
      }
      
      
      
      if (null != moduleList) {
        const moduleKeys = Object.getOwnPropertyNames(moduleAccessMatrix);
        for (const module of moduleList) {
          if ((module.isRead || module.isWrite)) {
            const moduleObject = {};
            moduleObject['moduleName'] = module['moduleName'];
            moduleObject['isRead'] = module['isRead'];
            moduleObject['isWrite'] = module['isWrite'];
            moduleObject['moduleUrl'] = module['moduleUrl'];
            const subModuleList = [];
            if (null != module['subModList']) {
              // tslint:disable-next-line: forin
              for (const subModule of module['subModList']) {
                if ((subModule.isRead || subModule.isWrite)) {
                  const subModuleObject = {};
                  subModuleObject['moduleName'] = subModule['subModuleName'];
                  subModuleObject['isRead'] = subModule['isRead'];
                  subModuleObject['isWrite'] = subModule['isWrite'];
                  subModuleObject['moduleUrl'] = subModule['subModUrl'];
                  subModuleList.push(subModuleObject);
                  if (moduleKeys.includes(subModule['subModuleName'])) {
                    if ((subModule['isRead'] && subModule['isWrite']) || (!subModule['isRead'] && subModule['isWrite'])) {
                      for (const routingLink of moduleAccessMatrix[subModuleObject['moduleName']]['W']) {
                        this.userAllowAccessRoutingLink.add(routingLink);
                      }
                    } else if (subModule['isRead'] && !subModule['isWrite']) {
                      for (const routingLink of moduleAccessMatrix[subModuleObject['moduleName']]['R']) {
                        this.userAllowAccessRoutingLink.add(routingLink);
                      }
                    }
                  }
                  //  this.userAllowAccessRoutingLink.push('/pages/' + moduleObject['moduleUrl'] + '/' + subModuleObject['moduleUrl']);
                }
              }
              moduleObject['subModuleList'] = subModuleList;
            } else {
              moduleObject['subModuleList'] = [];
              // this.userAllowAccessRoutingLink.push('/pages/' + moduleObject['moduleUrl']);
              if (moduleKeys.includes(module['moduleName'])) {
                if ((module['isRead'] && module['isWrite']) || (!module['isRead'] && module['isWrite'])) {
                  for (const routingLink of moduleAccessMatrix[moduleObject['moduleName']]['W']) {
                    this.userAllowAccessRoutingLink.add(routingLink);
                  }
                } else if (module['isRead'] && !module['isWrite']) {
                  for (const routingLink of moduleAccessMatrix[moduleObject['moduleName']]['R']) {
                    this.userAllowAccessRoutingLink.add(routingLink);
                  }
                }
              }
            }
            this.moduleList.push(moduleObject);
          }
        }
      }
      console.log(this.moduleList)
      if(this.moduleList && this.moduleList.length && this.moduleList.length > 1 && this.moduleList[0]['moduleUrl'] == 'cpn') {
        [this.moduleList[0], this.moduleList[1]] = [this.moduleList[1], this.moduleList[0]];
      }
      this.service.storeAccessMoudle(this.moduleList, this.userAllowAccessRoutingLink);
      const url = this.getUserLandingPage(this.moduleList);
      if (url !== '') {
        this.service.showMessage('success', 'Login successfully');
        this.router.navigateByUrl('/pages/' + url);
      } else {
        this.service.showMessage('error', 'There is no feature assign to you, Please connect with central usermanagement team to assign catalog feature');
        this.service.destorySession();
        this.router.navigateByUrl('/login');
      }
      // if (this.moduleList.length > 0 && this.moduleList[0].subModuleList.length === 0) {
      //   this.service.showMessage('success', 'Login successfully');
      //   this.router.navigateByUrl('/pages/' + this.moduleList[0]['moduleUrl']);
      // } else if (this.moduleList.length > 0 && this.moduleList[0].subModuleList.length > 0) {
      //   this.service.showMessage('success', 'Login successfully');
      //   this.router.navigateByUrl('/pages/' + this.moduleList[0]['moduleUrl'] + '/' + this.moduleList[0]['subModuleList'][0]['moduleUrl']);
      // } else {
      //   this.service.showMessage('error', 'There is no feature assign to you, Please connect with central usermanagement team to assign catalog feature');
      //   this.service.destorySession();
      //   this.router.navigateByUrl('/login');
      // }
    } else {
      this.invalid = true;
      this.service.showMessage('error', data['data']);
    }
  }

  getUserLandingPage(moduleList: any) {
    for (const module of moduleList) {
      if (module['isRead'] && module['subModuleList'].length === 0 && moduleAccessMatrix[module['moduleName']]['R'].length > 0) {
        return module['moduleUrl'];
      } else if (module['isWrite'] && module['subModuleList'].length === 0 && moduleAccessMatrix[module['moduleName']]['W'].length > 0) {
        return module['moduleUrl'];
      } else if (module['isRead'] && module['subModuleList'].length > 0) {
        for (const subModule of module['subModuleList']) {
          if (subModule['isRead'] && moduleAccessMatrix[subModule['moduleName']]['R'].length > 0) {
            return module['moduleUrl'] + '/' + subModule['moduleUrl'];
          } else if (subModule['isWrite'] && moduleAccessMatrix[subModule['moduleName']]['W'].length > 0) {
            return module['moduleUrl'] + '/' + subModule['moduleUrl'];
          }
        }
      } else if (module['isWrite'] && module['subModuleList'].length > 0) {
        for (const subModule of module['subModuleList']) {
          if (subModule['isRead'] && moduleAccessMatrix[subModule['moduleName']]['R'].length > 0) {
            return module['moduleUrl'] + '/' + subModule['moduleUrl'];
          } else if (subModule['isWrite'] && moduleAccessMatrix[subModule['moduleName']]['W'].length > 0) {
            return module['moduleUrl'] + '/' + subModule['moduleUrl'];
          }
        }
      } else {
        return '';
      }
    }

  }

  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.key.toUpperCase() === 'ENTER') {
  //     this.onSubmit();
  //   }
  // }

}
