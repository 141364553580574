
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';
import { urls } from '../../../urls'
import { params } from '../../../params';
import { DataService } from '../../../../data.service';
import { DatePipe } from '@angular/common';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import * as moment from "moment";

@Component({
  selector: 'verifyproduct',
  templateUrl: './verifyproduct.component.html',
  styleUrls: ['./verifyproduct.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush

})
export class VerifyproductComponent implements OnInit {

  todayDate: any;
  startDate: any;
  endDate: any;
  uploadHistoryList: any = [];
  _status: FormGroup;

  _fileStatus = [
    { id: 1, name: 'Uploaded' },
    // { id: 2, name: 'Updated' },
    { id: 4, name: 'Updated' },
    { id: 3, name: 'Deleted' }
  ];
  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];

  constructor(private fb: FormBuilder, private router: Router, private _dataService: DataService, private datePipe: DatePipe) { }

  ngOnInit() {
    const moglixStart = new Date(2015, 0, 1);
    const today = new Date();
    // this._status = this.fb.group({
    //   _status: [null, Validators.required]
    // });
    // let toSelectStatus = this._fileStatus.find(c => c.id == 1);
    // this._status.get('_status').setValue(toSelectStatus);



    let _storeSelectFileStatus = JSON.parse(localStorage.getItem('_selectFileStatus')) ? JSON.parse(localStorage.getItem('_selectFileStatus')) : null;
    if (_storeSelectFileStatus != null) {
      let _selectFileStatus = this._fileStatus.find(status => status.id == _storeSelectFileStatus.id);
      this._status = this.fb.group({
        _status: _selectFileStatus
      });
    }
    else {
      let _selectFileStatus = this._fileStatus.find(status => status.id == 1);
      this._status = this.fb.group({
        _status: _selectFileStatus
      });
    }
    let _daterRange = JSON.parse(localStorage.getItem('verifyMSNDateRange'))
    if (_daterRange != null) {
      this.startDate = this._dateFormat(new Date(_daterRange.fromDate));
      this.endDate = this._dateFormat(new Date(_daterRange.toDate));
    }
    else {
      _daterRange = { fromDate: today, toDate: new Date(today.getTime() + (24 * 60 * 60 * 1000)) };
      localStorage.setItem('verifyMSNDateRange', JSON.stringify(this.range));
      this.startDate = this._dateFormat(_daterRange.fromDate);
      this.endDate = this._dateFormat(_daterRange.toDate);
    }

    const fromMin = new Date(moglixStart.getFullYear(), moglixStart.getMonth(), 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const toMin = new Date(moglixStart.getFullYear(), moglixStart.getMonth(), 1);
    const toMax = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);


    const resetRange = { fromDate: today, toDate: today };
    // this.dateRangePicker.resetDates(resetRange); // will trigger selectedDateRangeChanged

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: 'mediumDate',
      range: { fromDate: new Date(_daterRange.fromDate), toDate: new Date(_daterRange.toDate) },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        // hasBackDrop: false
      },
      // cancelLabel: "Cancel",
      // excludeWeekends:true,
      fromMinMax: { fromDate: fromMin, toDate: fromMax },
      toMinMax: { fromDate: toMin, toDate: toMax }
    };
  };

  updateRange(range: Range) {
    this.range = range;
    localStorage.setItem('verifyMSNDateRange', JSON.stringify(this.range));
    this.startDate = this._dateFormat(range.fromDate);
    this.endDate = this._dateFormat(range.toDate);
    this.getVerifyProductList(event, this._status);
  }

  _dateFormat(date?) {
    var today = date;
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  // helper function to create initial presets
  setupPresets() {
    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    const moglixStart = new Date(2015, 0, 1);

    this.presets = [
      { presetLabel: "Yesterday", range: { fromDate: yesterday, toDate: today } },
      { presetLabel: "Last 7 Days", range: { fromDate: minus7, toDate: today } },
      { presetLabel: "Last 30 Days", range: { fromDate: minus30, toDate: today } },
      { presetLabel: "This Month", range: { fromDate: currMonthStart, toDate: currMonthEnd } },
      { presetLabel: "Last Month", range: { fromDate: lastMonthStart, toDate: lastMonthEnd } },
      { presetLabel: "All", range: { fromDate: moglixStart, toDate: today } }
    ]
  }

  getVerifyProductList(event, model?) {
    let _selectFileStatus = this._fileStatus.find(status => status.name == model.value._status.name);
    localStorage.setItem('_selectFileStatus', JSON.stringify(_selectFileStatus));

    let file = model.value._status.name;

    let fileType: any;
    let fileState: any;
    if (file === 'Uploaded') {
      fileType = "Product Feed File";
      fileState = "Uploaded";
    }
    // else if (file == 'Updated') {
    //   fileType = "Product Bulk Update File";
    //   fileState = "Updated";
    // }
    else if (file == 'Deleted') {
      fileType = "Product Delete File";
      fileState = "Deleted";
    }

    let request =
    {
      //  "uploadedBy": this._dataService.getEmail(),
      "uploadedBy": null,
      "fromTime": this.startDate,
      "toTime": this.endDate,
      //  "fileState": model ? model.value._status.name : 'Uploaded',
      "fileState": fileState,
      "fileType": fileType
    }
    let url = '';
    if(file == 'Updated') {
      request['eventType'] = 'Product';
      request['operationType'] = 'Update';
      delete request['uploadedBy'];
      url = params.platform_data_feed + urls.fetchAsyncData;
    } else {
      url = params.old_prod_supplier + urls.OLD.verifyProduct;
    }
    this._dataService.callRestful("POST", url, request, null, null, null, true).subscribe(
      (data) => {
        this._dataService.offSpinner();
        if (data['status']) {
          if (data['data']) {
            this.uploadHistoryList = data['data']['uploadHistoryList'];
            if (null != this.uploadHistoryList) {
              this._dataService.showMessage("success", "Data fetch successfully");
              this.uploadHistoryList.sort(function (a, b) {
                var dateA: any = new Date(a.createdOn);
                var dateB: any = new Date(b.createdOn);
                return dateB - dateA;
              });
            }
            else{
              this.uploadHistoryList = [];
              this._dataService.showMessage("error", "No data found");
            }
          }
        }
        else {
          this.uploadHistoryList = [];
          //   this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        }
      },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });

  }

  //     this._dataService.callRestful("POST", url, request, null, null, null, true).subscribe(
  //     (data) => {
  //       this.mappedResult(data);
  //     },
  //     (err) => {
  //       this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
  //     });

  // }


  // mappedResult(data) {
  //   this._dataService.offSpinner();
  //   if (!data['status']) {
  //     this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
  //   }
  //   else{
  //     this.uploadHistoryList = data['data']['uploadHistoryList'];
  //   }
  // }

  deleteFile(uploadId) {
    let url = params.old_prod_supplier + urls.OLD.deleteUploadedFile + "?uploadId=" + uploadId;
    this._dataService.callRestful("GET_MSN", url, null, null, null, null, true).subscribe(
      (data) => {
        this._dataService.offSpinner();
        if (data['status']) {
          this._dataService.showMessage("success", "Delete file successfully");
          let i = this.uploadHistoryList.findIndex(x => x.uploadId == uploadId);
          if (i >= 0) {
            this.uploadHistoryList.splice(i, 1);
          }
        }
        else {
          this._dataService.showMessage("error", data['data']);
        }
      },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      }); 
  }

  viewsucceed(uploadId?) {
    this.router.navigateByUrl('/pages/products/verify/view-succeeded/' + uploadId);
  }

  viewwarning(uploadId?) {
    this.router.navigateByUrl('/pages/products/verify/view-warning/' + uploadId);
    
  }
  viewfailed(uploadId?) {
    this.router.navigateByUrl('/pages/products/verify/view-failed/' + uploadId);
  }

}


