import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MsnService } from 'src/app/pages/msn/msn.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-log-header',
  templateUrl: './log-header.component.html',
  styleUrls: ['./log-header.component.css']
})
export class LogHeaderComponent implements OnInit {
  msnTypeArray = [];
  type = 1;
  auditType: string;
  @Input() logHeaders = {};
  title: string;
  @Output() emitAuditType = new EventEmitter<any>();


  constructor(private router: Router, public msnService: MsnService, private _dataService: DataService, ) {


  }

  ngOnInit() {
    this.title = this.logHeaders['title'];
    this.msnTypeArray = this.logHeaders['buttonList'];
    this.showAudit(this.type);

  }


  ngOnChanges(changes: SimpleChanges) {

    this.showAudit(this.type);

  }

  showAudit(selectedType) {
    this.msnTypeArray.forEach(typeObj => {
      if (typeObj['id'] === selectedType) {
        typeObj['show'] = true;
        this.type = typeObj['id'];
        typeObj['show'] = true;
        this.auditType = typeObj['path'];
        this.emitAuditType.emit(this.auditType);
      }
      else {
        typeObj['show'] = false;
      }
    });
  }

  auditViewAll() {
    this.router.navigateByUrl('/pages/msn/audit-view-all/' + this.auditType);

    //this.router.navigateByUrl('/pages/msn/audit-view-all/' + this.type);

  }
}