import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateService {


  public selectedGroup: Subject<Object> = new Subject<Object>();
  public groupUpdated = new EventEmitter();
  public attributeUpdated = new EventEmitter();
  public msnUpdated = new EventEmitter();
  public createdGroupUpdate = new EventEmitter();
  public attriUpdate = new EventEmitter();
  public msnlistUpdated= new EventEmitter();
  public clearGroupUpdated = new EventEmitter();
  public selectedGroupUpdate = new EventEmitter();
  public createdGroupsUpdate = new EventEmitter();
  removeGroupUpdated  = new EventEmitter();
  updateListedAttribute = new EventEmitter();
  updateListedAttributeView = new EventEmitter();
  selectedgroupCreate : any;
  group:any;
  attributeList: any = [];
  mappedMSNList : any = {};
  objAttributeList: any;
  attrList: any;
  msnList : any = {};
  groups: any = [];
  listedAttributeList: any;
  listedAttributListView: any;
  
  constructor() { }

  //Create Grouping
  setCreatedGroup(group){
    // this.attrList = [];
    // this.msnList = [];
    this.group = group;
    this.createdGroupUpdate.emit(this.group);
  }

  setCreatedGroup1(group){
    this.group = group;
    //this.createdGroupUpdate.emit(this.group);
  }

  getCreatedGroup(): Observable<any> {
    return this.group;
  }

  setGroups(groups){
    this.groups = groups;
    this.group = groups[0];
    this.createdGroupsUpdate.emit(this.groups);
  }

  getGroups(): Observable<any> {
    return this.groups;
  }

  setCreateAttrList(attributeList,objAttributeList){
    this.attrList = attributeList;
    this.objAttributeList = objAttributeList;
    if(this.group['attributeDetails']== null){
    this.group['attributeDetails'] = [...this.objAttributeList];
    }else{
      this.group['attributeDetails'] = [...this.group['attributeDetails'],...this.objAttributeList];
    }
    let arr = this.getUniqueAttributeList(this.group['attributeDetails']);
    this.group['attributeDetails'] = arr;
    this.attriUpdate.emit(this.group);
  }

  attributrList(attributeList,attribute){
    this.listedAttributeList = attribute;
    let obj = {attributeList : attributeList, listedAttributeList: this.listedAttributeList}
    this.updateListedAttribute.emit(obj);
  }

  getUniqueAttributeList(arr) {
    const ids = arr.map(o => o.attributeId)
    const filtered = arr.filter(({attributeId}, index) => !ids.includes(attributeId, index + 1))
    return filtered;
  }

  // getCreateArrtributeList(): Observable<any> {
  //   return this.attrList;
  // }
  // getCreateObjArrtributeList(): Observable<any> {
  //   return this.objAttributeList;
  // }

  setSelectedGroup(group){
    this.selectedgroupCreate = group;
    this.selectedGroupUpdate.emit(this.selectedgroupCreate);
  }
  getSelectedGroup(){
    return this.selectedgroupCreate;
  }

  setMSNList(msnList){
    this.msnList = msnList;
    this.msnlistUpdated.emit(this.msnList);
  }


  removeGroup(){
    this.removeGroupUpdated.emit(null);
  }

  // getMSNList(): Observable<any> {
  //   return this.msnList;
  // }



  //view grouping
  setGroup(group) {
    this.selectedGroup = group;
    this.groupUpdated.emit(group);
  }

  getGroup(): Observable<any> {
    return this.selectedGroup;
  }

  setMappedAttribute(attributes){
    this.attributeList = [...this.selectedGroup['attributeDetails'],...attributes];
    this.selectedGroup['attributeDetails'] = this.attributeList;
    let arr = this.getUniqueAttributeList( this.selectedGroup['attributeDetails']);
    this.selectedGroup['attributeDetails'] = arr;
    this.attributeUpdated.emit(this.selectedGroup);
  }

  setMappedMSNList(msnList){
    this.mappedMSNList = msnList;
    this.msnUpdated.emit(this.mappedMSNList);
  }

  // getMappedMSNList(): Observable<any> {
  //   return this.mappedMSNList;
  // }
  attributeListView(attributeList, attribute){
    this.listedAttributListView = attribute;
    let obj = {attributeList : attributeList, listedAttributListView: this.listedAttributListView}
    this.updateListedAttributeView.emit(obj);
  }

  clearGroup(){
    this.clearGroupUpdated.emit(null);
  }
}
