
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsRoutingModule } from './products.routing.module';
import { ProductsComponent } from './products.component';
import { BulkNewUploadModule } from './bulk-new-upload/bulk-new-upload.module';
import { VerifyModule } from './verify/verify.module';
import { DownloadModule } from './download/download.module';
import { VerifyproductRoutingModule } from './verify/verifyproduct/verifyproduct-routing.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ProductsRoutingModule,
    BulkNewUploadModule,
    DownloadModule,
    VerifyModule,
    VerifyproductRoutingModule
    
  ],
  declarations: [ProductsComponent]
})
export class ProductsModule { }
