import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { params } from '../../../params';
import { urls } from '../../../urls';
import { DataService } from '../../../../data.service';
import { MatCheckbox } from '@angular/material';
import { jitExpression } from '@angular/compiler';

@Component({
  selector: 'app-view-warning',
  templateUrl: './view-warning.component.html',
  styleUrls: ['./view-warning.component.css']
})
export class ViewWarningComponent implements OnInit {

  uploadId: any;
  productRawResponseList: any = [];
  productRawList: any = [];
  selectVerifyProduct: any = [];
  selectAllCheckBox: any = false;
  disableVerifyButton: any = true;
  disableDiscardButton: any = true;
  pageName: any;
  responseFlag: any = false;
  failureCaseResponse: any = {};
  passedCaseResponse: any = [];
  failureKeys: any = {};
  selectAllCheckBoxFlag: any = true;

  constructor(private router: Router, private _dataService: DataService, private paramRoute: ActivatedRoute) { }

  ngOnInit() {
    this.uploadId = this.paramRoute.snapshot.paramMap.get("id");
    this.getFetchVerifyProduct();
  }

  getFetchVerifyProduct() {
    let url = params.old_prod_supplier + urls.OLD.verifyViewWarning + "?uploadId=" + this.uploadId;
    this._dataService.callRestful("GET_MSN", url, null, null, null, null).subscribe((data) => {
      this.mappedResult(data);
      this.disabledButtonFunctionality();
    },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }
  mappedResult(data) {
    this._dataService.offSpinner();
    if (!data['status']) {
      this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
    }
    else {
      this.productRawList = [];
      this.productRawResponseList = data['data']['productRawResponseList'];
      if (null != this.productRawResponseList) {
        for (let i = 0; i < this.productRawResponseList.length; i++) {
          let productRaw: any = {};

          productRaw['productId'] = this.productRawResponseList[i]['productId'];
          productRaw['productName'] = this.productRawResponseList[i]['productName'];
          productRaw['brand'] = this.productRawResponseList[i]['brand'];
          productRaw['manufacture'] = this.productRawResponseList[i]['manufacturer'];
          productRaw['shortDescription'] = this.productRawResponseList[i]['shortDescription'];
          productRaw['internal_part_number'] = this.productRawResponseList[i]['variableFieldsByAttributeName']['Internal Part Number'];
          productRaw['verifiedStatus'] = this.productRawResponseList[i]['verifiedStatus'];
          productRaw['msn'] = this.productRawResponseList[i]['moglixPartNumber'];
          productRaw['categoryHierarchy'] = this.productRawResponseList[i]['categoryHierarchy'];
          for (let j = 0; j < productRaw['categoryHierarchy'].length; j++) {
            let name = "category_level_" + (j + 1);
            productRaw[name] = productRaw['categoryHierarchy'][j]['name'];
          }
          productRaw['warningMsg'] = this.productRawResponseList[i]['warningMsg'];
          productRaw['deletedFlag'] = this.productRawResponseList[i]['deletedFlag'];
          productRaw['isChecked'] = false;
          productRaw['mapped'] = this.productRawResponseList[i]['mapped'];
          this.productRawList.push(productRaw);
        }
      }
      //  this.disabledButtonFunctionality();
    }
  }

  selectAll(isSelect) {
    this.selectVerifyProduct = [];
    if (isSelect) {
      for (var i in this.productRawList) {
        this.productRawList[i]['isChecked'] = true;
        if (!this.productRawList[i].mapped && !this.productRawList[i].deletedFlag)
          this.selectVerifyProduct.push(this.productRawList[i]);
      }
    }
    else {
      for (var j in this.productRawList) {
        this.productRawList[j]['isChecked'] = false;
      }
      //   this.selectVerifyProduct = [];
    }

    this.disabledButtonFunctionality();
    // if (this.selectVerifyProduct.length > 0) {
    //   this.disableVerifyButton = false;
    //   this.disableDiscardButton = false;
    // }
    // else {
    //   this.disableVerifyButton = true;
    //   this.disableDiscardButton = true;
    // }
  }

  disabledButtonFunctionality() {
    let count = 0;
    let _cnt = 0;
    for (var j in this.productRawList) {
      if (this.productRawList[j].msn == null && !this.productRawList[j].mapped && !this.productRawList[j].deletedFlag)
        count++;
      else
        _cnt++;
    }

    if (count > 0 && this.productRawList.length > 0) {
      if (this.selectVerifyProduct.length > 0) {
        this.disableVerifyButton = false;
        this.disableDiscardButton = false;
      }
      else {
        this.disableVerifyButton = true;
        this.disableDiscardButton = true;
      }

      if (count > 0)
        this.selectAllCheckBoxFlag = true;
      else
        this.selectAllCheckBoxFlag = false;

      if ((_cnt + this.selectVerifyProduct.length) >= this.productRawList.length)
        this.selectAllCheckBox = true;
      else
        this.selectAllCheckBox = false;

    }
    else
      this.updateProductVerifyDisable();
  }

  updateProductVerifyDisable() {
    let _cnt = 0;
    let _count = 0;
    for (var j in this.productRawList) {
      if (this.productRawList[j].msn != null && !this.productRawList[j].mapped && !this.productRawList[j].deletedFlag)
        _cnt++;
      else
        _count++;
    }

    if (this.productRawList.length > 0) {
      if (_cnt > 0 && this.selectVerifyProduct.length > 0) {
        this.disableVerifyButton = false;
        this.disableDiscardButton = true;
      }
      else {
        this.disableVerifyButton = true;
        this.disableDiscardButton = true;
      }

      // if (this.productRawList.length == _cnt)
      //   this.selectAllCheckBox = true;
      // else
      //   this.selectAllCheckBox = false;


      if (_cnt > 0)
        this.selectAllCheckBoxFlag = true;
      else
        this.selectAllCheckBoxFlag = false;


      if ((_count + this.selectVerifyProduct.length) >= this.productRawList.length)
        this.selectAllCheckBox = true;
      else
        this.selectAllCheckBox = false;

    }
  }

  selectVerifiedProduct(e, product) {
    //  let e = <MatCheckbox>event;
    let k = this.productRawList.findIndex(x => x.productId == product.productId);
    this.productRawList[k].isChecked = e.checked;
    if (e && e.checked) {
      this.selectVerifyProduct.push(product);
    } else {
      let i = this.selectVerifyProduct.findIndex(x => x.productId == product.productId);
      if (i >= 0) {
        this.selectVerifyProduct.splice(i, 1);
      }
    }

    // if (this.selectVerifyProduct.length === this.productRawList.length) {
    //   //  document.getElementById('selectedAll1')['isChecked'] = true;
    //   this.selectAllCheckBox = true;
    // }
    // else {
    //   this.selectAllCheckBox = false;
    //   //  document.getElementById('selectedAll1')['isChecked'] = false;
    // }

    this.disabledButtonFunctionality();
    // if (this.selectVerifyProduct.length > 0) {
    //   this.disableVerifyButton = false;
    //   this.disableDiscardButton = false;
    // }
    // else {
    //   this.disableVerifyButton = true;
    //   this.disableDiscardButton = true;
    // }
  }

  getVerifyProduct() {
    let productIdList = this.selectVerifyProduct.map(x => { return x.productId });
    if (productIdList.length > 0) {
      let request =
      {
        "productList": productIdList,
        "requestedBy": this._dataService.getEmail()
      }

      let url = params.old_prod_supplier + urls.OLD.verify;
      this._dataService.callRestful("POST", url, request, null, null, null, true).subscribe(
        (data) => {
          this._dataService.offSpinner();
          if (data['status']) {
            this._dataService.showMessage("success", "Verify MSN response successfully");
            this.getFetchVerifyProduct();
            // remove this part from here
            //  this.responseFlag = true;
            this.pageName = "Verify";
            this.failureCaseResponse = data['data']['failureDetails'];
            this.failureKeys = Object.keys(this.failureCaseResponse);
            this.passedCaseResponse = data['data']['passedProducts'];
            this.selectVerifyProduct = [];
            this.disabledButtonFunctionality();
            this.selectAllCheckBox = false;
            //////////////////////////
          }
          else {
            this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
          }
        },
        (err) => {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        });
    }
  }


  getUnVerifyProduct() {
    let productIdList = this.selectVerifyProduct.map(x => { return x.productId });
    if (productIdList.length > 0) {
      let request =
      {
        "productList": productIdList,
        "requestedBy": this._dataService.getEmail()
      }

      let url = params.old_prod_supplier + urls.OLD.discard;
      this._dataService.callRestful("POST", url, request, null, null, null, true).subscribe(
        (data) => {
          this._dataService.offSpinner();
          if (data['status']) {
            this._dataService.showMessage("success", "Discard MSN response successfully");
            if (data['data']) {
              this.responseFlag = true;
              this.pageName = "Discard";
              this.failureCaseResponse = data['data']['failureDetails'];
              this.failureKeys = Object.keys(this.failureCaseResponse);
              this.passedCaseResponse = data['data']['passedProducts'];
              this.selectVerifyProduct = [];
              this.disabledButtonFunctionality();
              this.selectAllCheckBox = false;
            }
          }
          else {
            this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
          }
        },
        (err) => {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        });
    }
  }

  close() {
    this.responseFlag = false;
  }


  getKey(key) {
    return this.failureCaseResponse[key];
  }

  downladCSV() {
    if (this.productRawList == '')
      return;
    this.JSONToCSVConvertor(this.productRawList, "Success MSN Report", true);
  }
  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '';
    //Set Report title in first row or line

    CSV += ReportTitle + '\r\n\n';

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {

        //Now convert each value to string and comma-seprated
        row += index + ',';
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.setAttribute('visibility', 'hidden');
    link.download = "successMSN" + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  proddetail(productId) {
    this.router.navigateByUrl('/pages/products/verify/product-detail/' + productId);
  }



}
