


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapSuppProdComponent } from './mapsupp-prod/mapsupp-prod.component';
import { MapSupplierProductComponent } from './mapsupplierproduct.component';
import { MapSupplierProductRoutingModule } from './mapsupplierproduct-routing.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule, MatInputModule, MatNativeDateModule, MatSelectModule, MatExpansionModule, MatRadioModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MapSupplierProductRoutingModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatRadioModule,
  ],
  declarations: [MapSupplierProductComponent,MapSuppProdComponent]
})
export class MapSupplierProductModule { }
