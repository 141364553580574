

/**
 * Created by nitish ranjan on 27/09/18.
 */
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule }  from '@angular/router';
import { VerifyproductComponent } from './verifyproduct.component';

const routes: Routes = [
  {
    path: '',
    component: VerifyproductComponent,
    children: []
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  declarations:[],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule]
  
})
export class VerifyproductRoutingModule { }
