import { Injectable } from '@angular/core';
import { DataService } from '../../data.service';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { params } from '../params';
import { urls } from '../urls';

@Injectable({
  providedIn: 'root'
})
export class MsnService {
  auditType: any
  public auditTableArray = [];
  public msnId: any;
  public body: {}
  public productName = new BehaviorSubject(null);
  public categoryForMSN = new BehaviorSubject(null);
  public globalCategoryForMSN = new BehaviorSubject(null);

  constructor(public service: DataService, public http: HttpClient, private paramRoute: ActivatedRoute) {
    this.msnId = this.paramRoute.snapshot.paramMap.get("msn");
  }

  setAuditType(auditTypeVal) {
    this.auditType = auditTypeVal;
  }

  setMsnId(msnIdVal) {
    this.msnId = msnIdVal;
    localStorage.setItem('MSN', (this.msnId));
  }

  getMsnId() {
    return (localStorage.getItem('MSN'));
  }

  setProductName(productName) {
    this.productName.next(productName);
  }

  getProductName() {
    return this.productName;
  }

  setGlobalCategoryName(category: any) {
    this.globalCategoryForMSN.next(category);
  }

  getGlobalCategory() {
    return this.globalCategoryForMSN;
  }

  setCategoryName(category: any) {
    this.categoryForMSN.next(category);
  }

  getCategoryName() {
    return this.categoryForMSN;
  }




  getAuditDetails(obj) {
    let body = {}
    let entityType: string;

    if (obj.AuditType == "nonprice") {
      entityType = "PRODUCT"
    }
    else if (obj.AuditType == "price") {
      entityType = "PRODUCT-SUPPLIER PRICE"
    }
    else {
      entityType = "CPN-MSN MAPPING"
    }
    body = {
      "queryMessage": {
        "appID": params.audit_log_appID,
        "entity": entityType,
        "dataKey": this.msnId
      }
    }
    if (obj.AuditType == "nonprice") {
      let auditlogURL = params.audit_log + urls.auditLog.nonPrice + '?pageNo=' + obj.pageNo + '&pageSize=' + obj.pageSize

      return this.service.callRestful('POST', auditlogURL, body, null, null, null)
    }
    else if (obj.AuditType == "price") {
      let auditlogURL = params.audit_log + urls.auditLog.price + '?pageNo=' + obj.pageNo + '&pageSize=' + obj.pageSize

      return this.service.callRestful('POST', auditlogURL, body, null, null, null)
    }
    else if(obj.AuditType == "cpn"){
      let auditlogURL = params.audit_log + urls.auditLog.CPNMSN + '?pageNo=' + obj.pageNo + '&pageSize=' + obj.pageSize
      return this.service.callRestful('POST', auditlogURL, body, null, null, null)

    }
  }


  getAuditFiltersDetails(obj) {
    let body = {}
    let entityType: string;

    if (obj.AuditType == "nonprice") {
      entityType = "PRODUCT"
    }
    else if (obj.AuditType == "price") {
      entityType = "PRODUCT-SUPPLIER PRICE"
    }
    else {
      entityType = "CPN-MSN MAPPING"
    }

    body = {
      "queryMessage": {
        "appID": params.audit_log_appID,
        "entity": entityType,
        "dataKey": this.msnId
      },

      "filter": {
        "fromTime": obj.fromDate,
        "toTime": obj.toDate
      }
    }
    if (obj.AuditType == "nonprice") {
      let auditlogURL = params.audit_log + urls.auditLog.nonPrice + '?pageNo=' + obj.pageNo + '&pageSize=' + obj.pageSize
      return this.service.callRestful('POST', auditlogURL, body, null, null, null)
    }
    else if (obj.AuditType == "price") {
      let auditlogURL = params.audit_log + urls.auditLog.price + '?pageNo=' + obj.pageNo + '&pageSize=' + obj.pageSize

      return this.service.callRestful('POST', auditlogURL, body, null, null, null)
    }
    else {
      let auditlogURL = params.audit_log + urls.auditLog.CPNMSN + '?pageNo=' + obj.pageNo + '&pageSize=' + obj.pageSize
      return this.service.callRestful('POST', auditlogURL, body, null, null, null)

    }
  }



  getAuditType() {
    return this.auditType;
  }
}

