import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './products.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent,
    children: [
      {path:'', redirectTo:'bulk-new-upload',pathMatch: 'full'},
      { path: 'bulk-new-upload', loadChildren: 'src/app/pages/products/bulk-new-upload/bulk-new-upload.module#BulkNewUploadModule' },
      { path: 'download', loadChildren: 'src/app/pages/products/download/download.module#DownloadModule' },
      { path: 'verify', loadChildren: 'src/app/pages/products/verify/verify.module#VerifyModule' }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }