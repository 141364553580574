

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewFailedRoutingModule } from './view-failed-routing.module';
import { ViewFailedComponent } from './view-failed.component';
import { 
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule, MatDatepickerModule, MatNativeDateModule,MatCheckboxModule} from '@angular/material';


@NgModule({
  imports: [
    CommonModule,
    ViewFailedRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule
  ],
  declarations: [ViewFailedComponent]
})
export class ViewFailedModule { }
