import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(categoryList: any[], searchText: string): any[] {
    if(!categoryList) return [];
    if(!searchText) return categoryList;
    const regexp = new RegExp(searchText, 'i');

    searchText = searchText.toLowerCase();
        return  categoryList.filter(x => x.categoryName.toLowerCase().includes(searchText.toLowerCase()));
   } 
}