import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private service: DataService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const isLoggedIn: boolean = this.service.getSession();
        if (isLoggedIn && this.checkUserAccessOnRouting(state.url)) {
            return isLoggedIn;
        } else if (isLoggedIn && !this.checkUserAccessOnRouting(state.url)) {
            this.service.showMessage('error', 'You are not authorized to access this module');
            this.router.navigateByUrl(this.router.url);
            return !isLoggedIn;
        } else {
            this.service.showMessage('error', 'Session Expired!, Please re-login in catalog portal');
            this.router.navigateByUrl('/login');
            return isLoggedIn;
        }
    }

    checkUserAccessOnRouting(stateUrl: any): boolean {
        const userAcessRoutingLinks = this.service.getRoutingLink();
        if (userAcessRoutingLinks.includes(stateUrl)) {
            return true;
        } else {
            for (const link of userAcessRoutingLinks) {
                if (stateUrl.indexOf(link) !== -1) {
                    return true;
                }
            }
            return false;
        }
    }

    // isSessionManaged(email: any, uname: any): boolean {
    //     // authenticate from db if the uname session is still valid
    //     if (email !== null || uname !==  null) {
    //         return true;
    //     }
    //     this.router.navigateByUrl('/login');
    //     return false;
    // }
}
