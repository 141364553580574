
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { VerifyRoutingModule } from './verify-routing.module';
import { VerifyComponent } from './verify.component';
import { ViewFailedModule } from './view-failed/view-failed.module';
import { ViewSucceededModule } from './view-succeeded/view-succeeded.module';
import { VerifyproductModule } from './verifyproduct/verifyproduct.module';
import { ProductDetailModule } from './product-detail/product-detail.module';
import { VerifyProdResponseComponent } from './verify-productresponse/verify-prod-response.component';
import { ViewWarnModule } from './view-warning/view-warn.module';

@NgModule({
  imports: [
    CommonModule,
    VerifyRoutingModule,
    ViewFailedModule,
    ViewSucceededModule,
    ViewWarnModule,
    VerifyproductModule,
    ProductDetailModule  
  ],
  declarations: [VerifyComponent,
    VerifyProdResponseComponent
],
  providers:[],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class VerifyModule { }
