
/**
 * Created by nitish ranjan on 28/09/18.
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { params } from './pages/params';
import { AppComponent } from './app.component';
import { NgaModule } from './theme/nga.module';
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule } from '@angular/common/http';
import { GlobalStateService } from './global-state.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
//import { ItemTrackingOTIFDetailedComponent } from './item-tracking-otif-detailed/item-tracking-otif-detailed.component';
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(params.google_login_id)
  }
]);

export function provideConfig() {
  return config;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.CLIENT_ID, //application client id
      // redirectUri: 'http://localhost:4200',//'https://buyersqanew.moglix.com',
      authority: `https://login.microsoftonline.com/${environment.TENANT_ID}`, //added tenant id
    }
  });
}


@NgModule({
  declarations: [
    AppComponent,
    //ItemTrackingOTIFDetailedComponent,
  ],
  imports: [
    BrowserModule,
    NgaModule,
    PagesModule,
    AppRoutingModule,
    HttpClientModule,
    InfiniteScrollModule,
    SocialLoginModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [
    GlobalStateService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
