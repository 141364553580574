import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewRoutingModule } from './view-routing.module';
import { MappedAttributeMsnComponent } from './mapped-attribute-msn/mapped-attribute-msn.component';
import { AttributeMsnMappingComponent } from './attribute-msn-mapping/attribute-msn-mapping.component';
import { GroupingFilterComponent } from './grouping-filter/grouping-filter.component';
import { ViewComponent } from './view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule,MatDatepickerModule,MatCheckboxModule,MatDividerModule,MatButtonModule,MatCardModule,MatTabsModule,MatMenuModule,MatIconModule,MatAutocompleteModule,MatGridListModule,MatDialogModule,MatSelectModule, MatInputModule,MatNativeDateModule,MatExpansionModule,MatProgressSpinnerModule} from '@angular/material';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatTableModule } from '@angular/material';
import { MaxLengthPipeModule } from '../../unmapped/verify-cpn/maxlength.pipe';

@NgModule({
  imports: [
    CommonModule,
    ViewRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    Ng2SearchPipeModule,
    MatTableModule,
    MaxLengthPipeModule,
    MatFormFieldModule,MatDatepickerModule,MatCheckboxModule,MatDividerModule,MatButtonModule,MatCardModule,MatTabsModule,MatMenuModule,MatIconModule,MatAutocompleteModule,MatGridListModule,MatDialogModule,MatSelectModule, MatInputModule,MatNativeDateModule,MatExpansionModule,MatProgressSpinnerModule
  ],
  declarations: [ViewComponent, MappedAttributeMsnComponent, GroupingFilterComponent, AttributeMsnMappingComponent]
})
export class ViewModule { }
