import { BulkNewUploadComponent } from './bulk-new-upload.component';
import { BulkNewUploadRoutingModule } from './bulk-new-upload-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload/upload.component';
import { NewProdResponseComponent } from './new-productresponse/new-prod-response.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule, MatInputModule, MatNativeDateModule, MatSelectModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    BulkNewUploadRoutingModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [BulkNewUploadComponent, UploadComponent, NewProdResponseComponent]
})
export class BulkNewUploadModule { }
