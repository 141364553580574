import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablerComponent } from './tabler/tabler.component';
import {   MatPaginatorModule } from '@angular/material';
import { SharedService } from './shared.service';
import { LogHeaderComponent } from './log-header/log-header.component';
@NgModule({
  imports: [
    MatTableModule,
    MatPaginatorModule,
    CommonModule
  ],
  declarations: [TablerComponent, LogHeaderComponent],
  exports:[TablerComponent,LogHeaderComponent]
})
export class SharedModule { 
  static forRoot(){
    return {
      ngModule:SharedModule,
      providers:[SharedService]
    }
  }
}
