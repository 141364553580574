import { Directive,Input } from '@angular/core';
//import {  ElementRef, , OnInit } from ‘@angular/core’;

@Directive({
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective {
/*
  @Input () infiniteScrollDistance;
  @Input () infiniteScrollDistance;
  @Input () infiniteScrollDistance;
  @Input () infiniteScrollDistance;

            [infiniteScrollDistance]="2"
            [infiniteScrollUpDistance]="1.5"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScrollDown()"
            (scrolledUp)="onScrollUp()">
        </div>  */
  constructor() { }

}
