import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';

import { DataService } from '../../../../../data.service';
import { GlobalStateService } from '../../../../../global-state.service';
import { urls } from '../../../../urls'
import { params } from '../../../../params';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-download-product-view',
  templateUrl: './download-product-view.component.html',
  styleUrls: ['./download-product-view.component.scss']
})
export class DownloadProductViewComponent implements OnInit {

  _requestStatus: FormGroup;
  startDate: any;
  endDate: any;
  dumpDownloadRequestList: any = [];
  displayedColumns: string[] = [
    "requestedBy",
    "requestedOn",
    "status",
    "failureReason"
  ];
  dataSource = new MatTableDataSource(this.dumpDownloadRequestList);
  requestStatus  = ["All","In progress", "Completed", "Failed", "Waiting"];
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  @ViewChild('dateRangePicker') dateRangePicker;

  constructor(private _state: GlobalStateService, private _dataService: DataService, private fb: FormBuilder) { }

  ngOnInit() {
    this._requestStatus = this.fb.group({ _requestStatus: ['In progress', Validators.required] });
    const moglixStart = new Date(2015, 0, 1);
    const today = new Date();
    const fromMin = new Date(moglixStart.getFullYear(), moglixStart.getMonth(), 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const toMin = new Date(moglixStart.getFullYear(), moglixStart.getMonth(), 1);
    const toMax = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    // let _daterRange = JSON.parse(localStorage.getItem('downloadDateRange'))
    // if (_daterRange != null) {
    //   this.startDate = this._dateFormat(new Date(_daterRange.fromDate));
    //   this.endDate = this._dateFormat(new Date(_daterRange.toDate));
    // }
    // else {
       const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }
      const minus7 = backDate(7);
      const resetRange = {fromDate: minus7, toDate: today};
      this.dateRangePicker.resetDates(resetRange);
      let  _daterRange = { fromDate: minus7, toDate: today };
      this.range= { fromDate: minus7, toDate: new Date() };
      localStorage.setItem('downloadDateRange', JSON.stringify(this.range));
      this.startDate = this._dateFormat(_daterRange.fromDate);
      this.endDate = this._dateFormat(_daterRange.toDate);
    //}
    this.setupPresets();
    this.options = {

      presets: this.presets,
      format: 'mediumDate',
      range: { fromDate: new Date(_daterRange.fromDate), toDate: new Date(_daterRange.toDate) },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        // hasBackDrop: false
      },
      // cancelLabel: "Cancel",
      // excludeWeekends:true,
      fromMinMax: { fromDate: fromMin, toDate: fromMax },
      toMinMax: { fromDate: toMin, toDate: toMax }
    };
    this.getProductDownloadRequestList();
  }

  _dateFormat(date?) {
    var today = date;
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }
  // helper function to create initial presets
  setupPresets() {
    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    const moglixStart = new Date(2015, 0, 1);

    this.presets = [
      { presetLabel: "Yesterday", range: { fromDate: yesterday, toDate: today } },
      { presetLabel: "Last 7 Days", range: { fromDate: minus7, toDate: today } },
      { presetLabel: "Last 30 Days", range: { fromDate: minus30, toDate: today } },
      { presetLabel: "This Month", range: { fromDate: currMonthStart, toDate: currMonthEnd } },
      { presetLabel: "Last Month", range: { fromDate: lastMonthStart, toDate: lastMonthEnd } },
      { presetLabel: "All", range: { fromDate: moglixStart, toDate: today } }
    ]
  }

  _MS_PER_DAY = 1000 * 60 * 60 * 24;
  dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
  }



  updateRangeForDownloadList(range: Range) {
    this.range = range;
    localStorage.setItem('downloadDateRange', JSON.stringify(this.range));
    this.startDate = this._dateFormat(range.fromDate);
    this.endDate = this._dateFormat(range.toDate);
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getProductDownloadRequestList();
  }

  getProductDownloadRequestList(){
    if(new Date(this.startDate) > new Date(this.endDate)){
      this._dataService.showMessage("error", "From date should be less than To date");
        return 0;
    }
    this._dataService.onSpinner();
    let url = params.old_report_module + urls.OLD.getProductDumpDownloadRequest+ '?pageNo=' + this.currentPage + '&pageSize=' + this.pageSize + '&status='+this._requestStatus.value._requestStatus;
    let body = {
      "fromTime":this.startDate,
      "toTime":this.endDate,
      "requestStatus": this._requestStatus.value._requestStatus,
    }
    this._dataService.callRestful("POST", url, body, null, null, null, false).subscribe(
      (data) => {
        this._dataService.offSpinner();
        if (data['status']) {
          if(data['data']  == null){
            this._dataService.showMessage("error", data['statusDescription']);

          }else{
            this.dumpDownloadRequestList = data['data'];
            this.totalSize =data.noOfRecord;
            this.dataSource.data = this.dumpDownloadRequestList;
          }
        } else {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        }
      },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }
}
