import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferPriceComponent } from './offer-price.component';
import { OfferPriceRoutingModule } from './offer-price-routing.module';
import { OfferPriceUploadModule } from './offer-price-upload/offer-price-upload.module';




@NgModule({
  imports: [
    CommonModule,
    OfferPriceRoutingModule,
    OfferPriceUploadModule
  ],
  declarations: [OfferPriceComponent]
})
export class OfferPriceModule { }
