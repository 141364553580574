/**
 * Created by nitish ranjan on 27/09/18.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { VerifyComponent } from './verify.component'

const routes: Routes = [
  {
    path: '', component: VerifyComponent,
    children: [
      { path: '', redirectTo: 'verifyproduct', pathMatch: 'full' },
      { path: 'verifyproduct', loadChildren: 'src/app/pages/products/verify/verifyproduct/verifyproduct.module#VerifyproductModule' },
      { path: 'product-detail/:id', loadChildren: 'src/app/pages/products/verify/product-detail/product-detail.module#ProductDetailModule' },
      { path: 'view-failed/:id', loadChildren: 'src/app/pages/products/verify/view-failed/view-failed.module#ViewFailedModule' },
      { path: 'view-succeeded/:id', loadChildren: 'src/app/pages/products/verify/view-succeeded/view-succeeded.module#ViewSucceededModule' },
      { path: 'view-warning/:id', loadChildren: 'src/app/pages/products/verify/view-warning/view-warn.module#ViewWarnModule'}
    ]
  }
];

@NgModule({
  imports: [
        CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class VerifyRoutingModule { }
