

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UpdateActiveStatusComponent } from './update-activestatus/update-activestatus.component';
import { StatusResponseComponent } from './status-response/status-response.component';
import { ActiveStatusRoutingModule } from './activestatus-routing.module';
import { ActiveStatusComponent } from './activestatus.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule, MatInputModule,MatNativeDateModule,MatSelectModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    ActiveStatusRoutingModule, 
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule
    
  ],
  declarations: [UpdateActiveStatusComponent,ActiveStatusComponent,StatusResponseComponent],
  providers:[]
})
export class ActiveStatusModule { }
