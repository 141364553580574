/**
 * Created by Nitish Ranjan on 15/10/2018.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
    name: 'highlight'
})
export class HighlightSearch implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: any, args: any): any {
        if (!args) {
            return value;
        }

        var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.

        const match = value.match(re);

        // If there's no match, just return the original value.
        if (!match) {
            return value;
        }

        const highlightvalue = value.replace(re, "<mark>" + match[0] + "</mark>")
      //  return this.sanitizer.bypassSecurityTrustHtml(highlightvalue)
        //   return value.replace(re, "<mark>" + args + "</mark>");
        return highlightvalue;
    }
}