import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CatalogGroupingRoutingModule } from './catalog-grouping-routing.module';
import { CatalogGroupingComponent } from './catalog-grouping.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateRoutingModule } from './create/create-routing.module';
import { CreateModule } from './create/create.module';
import { ViewModule } from './view/view.module';
import { ViewRoutingModule } from './view/view-routing.module';

@NgModule({
  imports: [
    CommonModule,
    CatalogGroupingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CreateRoutingModule,
    CreateModule,
    ViewModule,
    ViewRoutingModule
  ],
  declarations: [CatalogGroupingComponent]
})
export class CatalogGroupingModule { }
