


import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewSucceededRoutingModule } from './view-succeeded-routing.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ViewSucceededComponent } from './view-succeeded.component';


@NgModule({
  imports: [
    CommonModule,
    ViewSucceededRoutingModule,
    MatCheckboxModule
  ],
  declarations: [ViewSucceededComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ViewSucceededModule { }
