
/**
 * Created by nitish ranjan on 28/09/18.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgaModule } from '../theme/nga.module';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { LoginModule } from './login/login.module';
import { MappingService } from './cpn-mapping.service';
import { AuthGuard } from "./auth.guard";
import { ProductsModule } from './products/products.module';
import { UpdateProductsModule } from './updateproducts/updateproducts.module';
import { OfferPriceModule } from './offer-price/offer-price.module';
import { CatalogGroupingModule } from './catalog-grouping/catalog-grouping.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgaModule,
    PagesRoutingModule,
    LoginModule,
    ProductsModule,
    UpdateProductsModule,
    OfferPriceModule,
    CatalogGroupingModule
  ],
  declarations: [PagesComponent],
  providers: [MappingService, AuthGuard]
})
export class PagesModule {

}
