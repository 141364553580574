import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mapsupp-prod',
  templateUrl: './mapsupp-prod.component.html',
  styleUrls: ['./mapsupp-prod.component.scss']
})
export class MapSuppProdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
