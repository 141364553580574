


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadproductComponent } from './downloadproduct/downloadproduct.component';
import { DownloadComponent } from './download.component';
import { DownloadRoutingModule } from './download-routing.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule,
   MatInputModule, MatNativeDateModule, MatSelectModule,MatTableModule,
    MatExpansionModule, MatRadioModule, MatIconModule, MatCheckboxModule } from '@angular/material';
import {DatePipe} from '@angular/common';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SharedModule } from '../../shared.module';
import { MaxLengthPipeModule } from '../../unmapped/verify-cpn/maxlength.pipe';
import { MatPaginatorModule } from '@angular/material';
import { DownloadProductViewComponent } from './downloadproduct/download-product-view/download-product-view.component';
@NgModule({
  imports: [
    CommonModule,
    DownloadRoutingModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatExpansionModule,
    MatRadioModule,
    NgxMatDrpModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    SharedModule,
    MaxLengthPipeModule,
    MatPaginatorModule
  ],
  declarations: [DownloadproductComponent, DownloadComponent, DownloadProductViewComponent],
  providers: [DatePipe]
})
export class DownloadModule { }
