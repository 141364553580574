import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { params } from '../../../params';
import { urls } from '../../../urls';
import { DataService } from '../../../../data.service';

@Component({
  selector: 'view-failed',
  templateUrl: './view-failed.component.html',
  styleUrls: ['./view-failed.component.scss']
})
export class ViewFailedComponent implements OnInit {

  uploadId: any;
  productRawResponseList: any = [];
  productRawList: any = [];

  constructor(private router: Router, private _dataService: DataService, private paramRoute: ActivatedRoute) { }

  ngOnInit() {
    this.uploadId = this.paramRoute.snapshot.paramMap.get("id");
    this.getFetchFailureProduct();
  }

  getFetchFailureProduct()
  {
    let url = params.old_prod_supplier + urls.OLD.verifyFailure + "?uploadId=" + this.uploadId;
    this._dataService.callRestful("GET_MSN", url, null, null, null, null).subscribe((data) => {
      this.mappedResult(data);
    },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  mappedResult(data) {
    this._dataService.offSpinner();
    if (!data['status']) {
      this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
    }
    else {
      this.productRawList = [];
      this.productRawResponseList = data['data']['productRawResponseList'];
      // this.productRawResponseList = this.data;
      for (let i = 0; i < this.productRawResponseList.length; i++) {
        let productRaw: any = {};

        productRaw['productId'] = this.productRawResponseList[i]['productId'];
        productRaw['productName'] = this.productRawResponseList[i]['productName'];
        productRaw['brand'] = this.productRawResponseList[i]['brand'];
        productRaw['manufacture'] = this.productRawResponseList[i]['manufacturer'];
        productRaw['shortDescription'] = this.productRawResponseList[i]['shortDescription'];
        productRaw['internal_part_number'] = this.productRawResponseList[i]['internalPartNumber'];
        productRaw['msn'] = this.productRawResponseList[i]['moglixPartNumber'];
        productRaw['uploadFailureReasons'] = this.productRawResponseList[i]['uploadFailureReasons'];

        productRaw['categoryHierarchy'] = this.productRawResponseList[i]['categoryHierarchy'];
        for (let j = 0; j < productRaw['categoryHierarchy'].length; j++) {
          let name = "category_level_" + (j + 1);
          productRaw[name] = productRaw['categoryHierarchy'][j]['name'];
        } 
        productRaw['warningMsg'] = this.productRawResponseList[i]['warningMsg'];
        productRaw['deletedFlag'] = this.productRawResponseList[i]['deletedFlag'];
        productRaw['isChecked'] = false;
        this.productRawList.push(productRaw);
      }
    }
  }

  downladCSV()
  {
    if( this.productRawList == '')
    return;
    this.JSONToCSVConvertor( this.productRawList, "Failure MSN Report", true);
  }
  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
      //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
      
      var CSV = '';    
      //Set Report title in first row or line
      
      CSV += ReportTitle + '\r\n\n';
  
      //This condition will generate the Label/Header
      if (ShowLabel) {
          var row = "";
          
          //This loop will extract the label from 1st index of on array
          for (var index in arrData[0]) {
              
              //Now convert each value to string and comma-seprated
              row += index + ',';
          }
  
          row = row.slice(0, -1);
          
          //append Label row with line break
          CSV += row + '\r\n';
      }
      
      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
          var row = "";
          
          //2nd loop will extract each column and convert it in string comma-seprated
          for (var index in arrData[i]) {
              row += '"' + arrData[i][index] + '",';
          }
  
          row.slice(0, row.length - 1);
          
          //add a line break after each row
          CSV += row + '\r\n';
      }
  
      if (CSV == '') {        
          alert("Invalid data");
          return;
      }   
      
      //Generate a file name
      var fileName = "MyReport_";
      //this will remove the blank-spaces from the title and replace it with an underscore
      fileName += ReportTitle.replace(/ /g,"_");   
      
      //Initialize file format you want csv or xls
      var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
      var link = document.createElement("a");    
      link.href = uri;
      link.setAttribute('visibility', 'hidden');
      link.download = "FailureMSN" + ".csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

}
