import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {
    HeaderComponent,
    FooterComponent,
    MenuComponent
} from './components';


import { InfiniteScrollDirective } from './directive';
import { HighlightSearch } from './pipe';


const NGA_COMPONENTS = [
    HeaderComponent,
    FooterComponent,
    MenuComponent
];


@NgModule({
    declarations: [
        ...NGA_COMPONENTS,
        InfiniteScrollDirective,
        HighlightSearch
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatSlideToggleModule
    ],
    exports: [
        ...NGA_COMPONENTS,
        HighlightSearch
    ]
})
export class NgaModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders> {
            ngModule: NgaModule,
            providers: [
            ],
        };
    }
}
