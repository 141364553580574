import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogGroupingComponent } from './catalog-grouping.component';

const routes: Routes = [
  {
    path: '',
    component: CatalogGroupingComponent,
    children: [
      {path:'', redirectTo:'create',pathMatch: 'full'},
      { path: 'view', loadChildren: 'src/app/pages/catalog-grouping/view/view.module#ViewModule' },
      { path: 'create', loadChildren: 'src/app/pages/catalog-grouping/create/create.module#CreateModule' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogGroupingRoutingModule { }
