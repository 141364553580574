import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../data.service';
import { GlobalStateService } from '../../../global-state.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public show: boolean = false;
  modules = [];
  selectedCountryName: string;
  username: string;
  constructor(private router: Router, private service: DataService, private _state: GlobalStateService) {
    
    if(this.service.getSession() && null!=this.service.getSelectedCountryName())
    
    this.selectedCountryName =this.service.getSelectedCountryName();

   }

  ngOnInit() {
    this.modules = this.service.getModules();
    this.username = this.service.getEmail().split(".")[0];
   
  }
  getFlag(countryFlag){
      return "assets/img/"+this.selectedCountryName+".svg";
  }
  // logout() {
  //   this.service.destorySession();
  //   this.router.navigateByUrl('/login');
  // }
  logout() {
    this.service.destorySession();
    this.router.navigateByUrl('/login')
      .then(() => {
        window.localStorage.setItem('logged_in', 'false');
        // window.location.reload();

    
      });
  }

  navigateHome() {
    //   this.router.navigateByUrl('/pages');
    if (this.modules.length > 0 && this.modules[0].subModuleList.length === 0) {
      this.router.navigateByUrl('/pages/' + this.modules[0]['moduleUrl']);
    } else if (this.modules.length > 0 && this.modules[0].subModuleList.length > 0) {
      this.router.navigateByUrl('/pages/' + this.modules[0]['moduleUrl'] + '/' + this.modules[0]['subModuleList'][0]['moduleUrl']);
    } else {
      this.service.showMessage('error', 'There is no feature assign to you, Please connect with central usermanagement team to assign catalog feature');
      this.service.destorySession();
      this.router.navigateByUrl('/login');
    }
  }
}
