import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { MsnService } from 'src/app/pages/msn/msn.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-tabler',
  templateUrl: './tabler.component.html',
  styleUrls: ['./tabler.component.scss']
})
export class TablerComponent implements OnInit {
  @Input() tableResponse = [];
  @Input() tableHeaders = [];
  @Input() tableHeadersUI = [];
  @Input() filterValue = "";
  @Input() totalRecords: number;
  distableHeaders = [];
  //pageSizeOptions = 1;
  public tableSource = new MatTableDataSource(this.tableResponse);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() _pageSize: number;
  @Input() pageNo: number;
  @Input() myFilterValue: string;
  @Input() auditType: string;
  totalCount: number;
  pageSize: number;
  pageNumber: number;
  length = 100;
  changeFilteredText: any;


  @Output() emitPaginatorValue = new EventEmitter<any>();
  constructor(public msnService: MsnService, private _dataService: DataService, ) {
  }

  ngOnInit() {
    this.totalCount = this.totalRecords;
    this.pageSize = this._pageSize;
    this.pageNumber = this.pageNo;
    //this.tableSource.paginator = this.paginator;
    this.tableSource.paginator.length = this.totalCount;
    this.tableSource.sort = this.sort;
    this.tableSource.filter = this.changeFilteredText;
    if (this.tableResponse) {
      this.setDataSource(this.tableResponse, this.tableHeaders)

    }
  }

  ngAfterViewInit(): void {
    this.tableResponse;
    this.tableSource.paginator.length = this.totalCount;
    this.tableSource.paginator = this.paginator;
    this.tableSource.sort = this.sort;
    this.tableSource.filter = this.changeFilteredText;
  }

  @ViewChild(MatPaginator) set matPaginator(value: MatPaginator) {
    if (this.tableSource && value) {
      this.tableSource.paginator = value;

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeFilteredText = changes.myFilterValue.currentValue;
    this.tableSource.filter = this.changeFilteredText;
    if (this.auditType == "nonprice") {
      this.tableSource.filterPredicate = function (data, filter: string): boolean {
        if (data.action.value.toUpperCase() || data.productName.value.toUpperCase()) {
          return data.action.value.toUpperCase().includes(filter.toUpperCase()) || data.modifiedBy.value.includes(filter)
            || data.productName.value.toUpperCase().includes(filter.toUpperCase()) || data.priceList.value.toString().includes(filter)
            || data.india.value.toString().includes(filter)
        }
      };
      this.tableSource.data = this.tableResponse
    }
    else if (this.auditType == "price") {
      this.tableSource.filterPredicate = function (data, filter: string): boolean {

        if (data.action.value.toUpperCase() || data.v1.value.toUpperCase()) {
          return data.action.value.toUpperCase().includes(filter.toUpperCase())
            || data.v1.value.toUpperCase().includes(filter.toUpperCase()) || data.retailTp.value.toString().includes(filter)
            || data.bulkTp.value.toString().includes(filter) || data.modifiedBy.value.includes(filter);
        }

      };
      this.tableSource.data = this.tableResponse
    }
    else {
      this.tableSource.filterPredicate = function (data, filter: string): boolean {
        if (data.action.value.toUpperCase() || data.productName.value.toUpperCase()) {
          return data.action.value.toUpperCase().includes(filter.toUpperCase()) || data.modifiedBy.value.includes(filter)
            || data.productName.value.toUpperCase().includes(filter.toUpperCase());
        }
      };
      this.tableSource.data = this.tableResponse
    }
  }

  @ViewChild(MatSort) set matSort(value: MatSort) {
    if (this.tableSource && value) {
      this.tableSource.sort = value;
    }
  }
  isSticky(column: number): boolean {
    return (column === 0 || column === 1 || column === 2 || column === 3) ? true : false;
  }

  setDataSource(data, columns) {  // data -> array of elements; columns -> columns you wish to display
    this.tableSource = new MatTableDataSource(this.tableResponse);
    this.tableSource.data = [];
    this.tableSource.data = data as any[];
    this.distableHeaders = columns;
    this.tableSource.paginator.length = this.totalCount;
    if (this.paginator) {   // add check here
      this.tableSource.paginator = this.paginator;
    }
    if (this.sort) {  // add check here
      this.tableSource.sort = this.sort;
    }

  }

  getPaginatorData(value) {
    this.pageNumber = value.pageIndex;
    this.pageSize = value.pageSize;
    let obj = {
      "pageNo": this.pageNumber,
      "pageSize": this.pageSize,

    }
    this.emitPaginatorValue.emit(obj);

  }


}
