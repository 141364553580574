
import {NgModule} from '@angular/core';
import { MsnListComponent} from './map-cpn-msn/msn-list/msn-list.component';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SearchPipe } from './search.pipe';

@NgModule({
    imports: [CommonModule,
        InfiniteScrollModule,
        MatCheckboxModule
    ],
    declarations: [
        MsnListComponent,
        SearchPipe
    ],
    exports: [
        MsnListComponent,
        SearchPipe
    ]
  })
  export class SharedModule { 
    
  }