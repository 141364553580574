import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-new-upload',
  templateUrl: './bulk-new-upload.component.html',
  styleUrls: ['./bulk-new-upload.component.scss']
})
export class BulkNewUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
