import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
 
@Injectable()
  export class MapCpnMsnService {
      
    bundleMsnObject:Object;

    // remove msn from bundle called by bunlde dailog box
    private _msnBundle = new Subject<any>();
    public _msnBundleStream = this._msnBundle.asObservable();

    // show already assigned msn while mapping edit
    private _assignedMsn = new Subject<any>();
    public _assignedMsnStream = this._assignedMsn.asObservable();

    private _subscriptions: Map<string, Array<Function>> = new Map<string, Array<Function>>();

    setBundle(bundle) {
        this.bundleMsnObject = bundle;
    }

    getBunble() {
        return this.bundleMsnObject;
    }

    clearBundle() {
        this.bundleMsnObject = {};
    }

    notifyBundle(value){
        this._msnBundle.next(value);
    }

    notifyAssignedMsn(value) {
      this._assignedMsn.next(value);
    }

    subscribe(event: string, callback: Function) {
        let subscribers = this._subscriptions.get(event) || [];
        subscribers.push(callback);
    
        this._subscriptions.set(event, subscribers);
        return subscribers.length - 1;
      }
    
      unsubscribe(event: string, index: number) {
        let subscribers = this._subscriptions.get(event) || [];
        if (subscribers.length - 1 >= index) {
          subscribers.splice(index, 1);
        }
        this._subscriptions.set(event, subscribers);
      }
    
      _onEvent(data: any) {
        let subscribers = this._subscriptions.get(data['event']) || [];
    
        subscribers.forEach((callback) => {
          callback.call(null, data['data']);
        });
      }

  }