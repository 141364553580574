import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateRoutingModule } from './create-routing.module';
import { CreateComponent } from './create.component';
import { MatFormFieldModule,MatDatepickerModule,MatCheckboxModule,MatDividerModule,MatButtonModule,MatCardModule,MatTabsModule,MatMenuModule,MatIconModule,MatAutocompleteModule,MatGridListModule,MatDialogModule,MatSelectModule, MatInputModule,MatNativeDateModule,MatExpansionModule,MatProgressSpinnerModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { CreateCatalogGroupingComponent } from './create-catalog-grouping/create-catalog-grouping.component';
import { AttributeMsnMappingComponent } from './attribute-msn-mapping/attribute-msn-mapping.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatTableModule } from '@angular/material';
import { MaxLengthPipeModule } from '../../unmapped/verify-cpn/maxlength.pipe';

@NgModule({
  imports: [
    CommonModule,
    CreateRoutingModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSelectModule,
    FormsModule,MatAutocompleteModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDividerModule,MatButtonModule,
    MatCardModule,MatTabsModule,
    MatMenuModule,MatIconModule,
    MatGridListModule,MatDialogModule,
    DragDropModule,
    Ng2SearchPipeModule,
    MatTableModule,
    MaxLengthPipeModule
  ],
  declarations: [CreateComponent,CreateCatalogGroupingComponent, CreateGroupComponent, AttributeMsnMappingComponent]
})
export class CreateModule { }
