import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({ name: 'maxlength' })
export class MaxLengthPipe implements PipeTransform {
    transform(value: string, length: number): string {
        if(isArray(value)) {
            value = value.toString();
        }
        const defaultLength = 25;
        if (!value) { return ''; }
        if (length && value.length < length) {
            return value;
        }
        else if (length && value.length > length) {
            return value.substring(0, length + 1) + '...';
        } else if (value.length > defaultLength) {
            return value.substring(0, defaultLength) + '...';
        } else {
            return value;
        }
    }
}

@NgModule({
    imports: [],
    exports: [MaxLengthPipe],
    declarations: [MaxLengthPipe],
    providers: [],
})
export class MaxLengthPipeModule { }