


import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ViewWarningComponent } from './view-warning.component';
import { ViewWarnRoutingModule } from './view-warn-routing.module';


@NgModule({
  imports: [
    CommonModule,
    ViewWarnRoutingModule,
    MatCheckboxModule
  ],
  declarations: [ViewWarningComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ViewWarnModule { }
