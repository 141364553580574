import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateProductsRoutingModule } from './updateproducts.routing.module';
import { UpdateProductsComponent } from './updateproducts.component';
import { BulkUpdateModule } from './bulk-update/bulk-update.module';
import { ActiveStatusModule } from './active-status/activestatus.module';
import { MapSupplierProductModule } from './map-supplier-product/mapsupplierproduct.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UpdateProductsRoutingModule,
    BulkUpdateModule,
    MapSupplierProductModule,
    ActiveStatusModule
  ],
  declarations: [UpdateProductsComponent]
})
export class UpdateProductsModule { }
