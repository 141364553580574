import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfferPriceComponent } from './offer-price.component';

const routes: Routes = [
  {
    path: '',
    component: OfferPriceComponent,
    children: [
      { path: '', redirectTo: 'offer-price', pathMatch: 'full' },
      { path: 'offer-price', loadChildren: 'src/app/pages/offer-price/offer-price-upload/offer-price-upload.module#OfferPriceUploadModule' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfferPriceRoutingModule { }
