


/**
 * Created by nitish ranjan on 27/09/18.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule }  from '@angular/router';
import { ViewWarningComponent } from './view-warning.component';

const routes: Routes = [
  {
    path: '',
    component: ViewWarningComponent,
    children: []
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations:[],
  exports: [RouterModule]
})
export class ViewWarnRoutingModule { }
