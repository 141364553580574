/**
 * Created by nitish ranjan on 27/09/18.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule }  from '@angular/router';
import { BulkUpdateComponent } from './bulk-update.component'

const routes: Routes = [
  {
    path: '',
    component: BulkUpdateComponent,
    children: []
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  //exports: [RouterModule]
})
export class BulkUpdateRoutingModule { }
