/**
 * Created by nitish ranjan on 28/09/18.
 */
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor() {
    
  }
  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logged_in' || event.key === 'token') {
        window.location.reload()
      }
    }, false)
  }
}
