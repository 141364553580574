import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { VerifyproductRoutingModule } from './verifyproduct-routing.module';
import { VerifyproductComponent } from './verifyproduct.component';
import { 
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule, MatDatepickerModule, MatNativeDateModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DatePipe} from '@angular/common';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';

@NgModule({
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    VerifyproductRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatDrpModule
  ],
  declarations: [VerifyproductComponent],
  providers: [DatePipe]
})
export class VerifyproductModule { }
