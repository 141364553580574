
import { Component, OnInit } from '@angular/core';
import { urls } from '../../../urls'
import { params } from '../../../params';
import { GlobalStateService } from '../../../../global-state.service';
import { DataService } from '../../../../data.service';
import { MappingService } from '../../../cpn-mapping.service';
import { Utility } from  '../../../utility'

@Component({
  selector: 'update-activestatus',
  templateUrl: './update-activestatus.component.html',
  styleUrls: ['./update-activestatus.component.scss']
})
export class UpdateActiveStatusComponent implements OnInit {

  file: any;
  fileResponse: boolean = false;
  response: any = {};
  constructor(private _state: GlobalStateService, private _dataService: DataService, private _service: MappingService) { }

  ngOnInit() {
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    let flag = Utility.fileValidation(this.file );
    if(!flag)
    {
      this.file = null;
      this._dataService.showMessage("error", "Please upload file having extensions .csv only.");
    }
  }

  fileUpload() {
    let request = {
      "uploadedBy": this._dataService.getEmail(),
      "uploadedFor": "update active status",
      "uploadFilePath": "",
      "uploadFileName": "",
      "country": this._dataService.getSelectedCountryCode()
    }
    let url = params.old_prod_supplier + urls.OLD.updateActiveProductStatus;
    const uploadData = new FormData();
    uploadData.append('file', this.file, this.file.name);
    uploadData.append('request', JSON.stringify(request));

    this._dataService.callRestful("POST", url, uploadData, null, null, null, true).subscribe(
      (data) => {
        this._dataService.offSpinner();
        if (data['status']) {
          this._dataService.showMessage("success", "File uploaded successfully");
          if (data['data']['numFailed']) {
            this.fileResponse = true;
            // this.response['result'] = data['data'];
            // this._state.dialogBoxEvent(this.response);
            this._service.setResponseData(data['data']);
          }
          else {
            alert("File uploaded successfully");
            this.close();
          }
        }
        else {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        }
      },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  close() {
    this.file = null;
    this.fileResponse = false;
  }

  clearStack(event) {
    event.target.value = null;
  }


}
