/**
 * Created by nitish ranjan on 28/09/18.
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../data.service';
import { LoginComponent } from './login/login.component';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  countryfilter:boolean;
  countryList:any;
  constructor(private service :DataService,private router: Router) {
    
  }
  selectCountry(country){
    this.service.setSelectedCountryCode(country['countryCode'])
    this.service.setSelectedCountryName(country['countryName'])
    this.countryfilter = true;  
  }
 ngOnInit() {
    if(this.service.getToken() && null!=this.service.getSelectedCountryCode()){
      this.countryfilter =true;
    
    }
    else{
      if(this.service.getToken() && null!=this.service.getCountryList() && this.service.getCountryList().length>1){
        
        this.countryList = this.service.getCountryList();
        
      }
      if(this.service.getToken() && this.service.getCountryList().length == 1){
        this.countryList = this.service.getCountryList();
      this.service.setSelectedCountryCode(this.countryList[0]['countryCode'])
      this.service.setSelectedCountryName(this.countryList[0]['countryName'])
      
        this.countryfilter = true;
       // window.location.reload();
        
      }
    }
    if(!this.service.getToken()){
      this.router.navigateByUrl('/login');
    }
  }
  getCountryFlag(img){
    return "assets/img/"+img+".svg";
  }
}
