/**
 * Created by nitish ranjan on 28/09/18.
 */
import { Injectable } from '@angular/core';

@Injectable()
export class MappingService {

    cpnDetails: any;
    mappingDetails: any;
    pageNumber: any;
    response: any;
    ipn: any;

    // paasing cpn detail from unmapped cpn to msn mapping page
    getCpnDetail() {
        return this.cpnDetails;
    }
    setCpnDetail(_cpn) {
        this.cpnDetails = _cpn;
    }
    clearCpnDetail() {
        this.cpnDetails = null;
    }

    // Passing mapping details to unmapped cpn page after assigning msn against cpn
    setMappingDetail(_mappingDetail :any) {
        this.mappingDetails = _mappingDetail;
    }
    getMappingDetail() {
        return this.mappingDetails;
    }
    clearMappingDetail() {
        this.mappingDetails = null;
    }

    getPageNumber() {
        return this.pageNumber;
    }
    setPageNumber(_pageNumber) {
        this.pageNumber = _pageNumber;
    }
    
    clearPageNumber() {
        this.pageNumber = null;
    }

    setResponseData(_responseData) {
        this.response = _responseData;
    }

    getResponseData() {
        return this.response;
    }

    clearResponeData() {
        this.response = null;
    }

    getInternalPartNumber() {
        return this.ipn;
    }

    setInternalPartNumber(_ipn) {
        this.ipn = _ipn;
    }

    clearInternalPartNumber() {
        this.ipn = null;
    }
}
