import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Hero } from './hero';
import { HEROES } from './mock-heros';



@Injectable(
  { providedIn: 'root' }
)
export class DataService {

  ipnSource = new BehaviorSubject(null);
  keyname: any;
  msnType = new Subject<any>();;

  updatedIpn(ipn) {
    this.ipnSource.next(ipn);
  }

  hierarchyKey = new BehaviorSubject(null);


  sendHierarchyKey(hierarchyKey) {
    this.hierarchyKey.next(hierarchyKey);
  }

  setEnterpriseType(type) {
    this.msnType.next(type);
  }

 

  constructor(private http: HttpClient) {
    // var headers = new Headers();
    // headers.append('Content-Type', 'application/json');
  }

  // dummy data
  getHeroes(): Observable<Hero[]> {
    return of(HEROES);
  }

  getById(id: number): Observable<Hero> {
    return of(HEROES.find(hero => hero.id === id));
  }
  // dummy data ends here

  onSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'block';
    curtain.style.display = 'block';
  }

  offSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'none';
    curtain.style.display = 'none';
  }

  callRestful(type: string, url: string, body?: any, headerObject?: any, includeHeader?: boolean, params?: HttpParams, hideSpinner?: boolean): Observable<any> {
    if (!(hideSpinner != null && hideSpinner == false)) {
      this.onSpinner();
    }

    var headerOption = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, TRACE, OPTIONS, PATCH',
      'responseType': 'json'
    };
    if (includeHeader == null || includeHeader === true) {
    }
    // if (headerObject) {
    //   for (let prop in headerObject) {
    //     headerOption[prop] = headerObject[prop];
    //   }
    // }
    var headers = new HttpHeaders();
    // if (type === 'FILE_UPLOAD')
    //   headers.append('Content-Type', 'multipart/form-data');
    // else
    console.log("headerObject --->>>", headerObject)
    headers.append('Content-Type', 'application/json');

    if(headerObject && headerObject['moglixSupplierId']) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'moglixSupplierId': headerObject['moglixSupplierId']
      });
    }
    if(headerObject && headerObject['tenantId']) {
      console.log("headerObject &&&--->>>", headerObject['tenantId'])
      let tempheaders = new HttpHeaders();
      headers = tempheaders.set('Content-Type', 'application/json').set('tenantId',headerObject['tenantId']);

    }

    switch (type) {
      case 'GET':
        return this.http.get(url,
          {
            responseType: 'json',
            params: params,
            headers: headers
          })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'JSON':
        return this.http.get(url,
          {
            responseType: 'text',
            params: params

          })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'POST':
        return this.http.post<any>(url, body, { headers: headers })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'GET_MSN':
        return this.http.get(url, { headers: headers })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'DELETE':
        return this.http.get(url,
          {
            responseType: 'json',
            params: params

          })
          .pipe(
            catchError(this.catchError.bind(this))
          );

      default:
        console.log('Debug at type request ' + type);
        return null;
    }
  }

  catchError(err) {
    this.offSpinner();
    console.log(err);
    if (err.message) {
      this.showMessage('error', err.message);
      //alert("Error occurred, " + err.message + " try after some time");
    } else {
      this.showMessage('error', 'Network error accouured');
      //alert("Network error accouured");
    }
  }

  private handleError(error: Response | any, isNotError?: boolean, url?): Observable<Response> {
    if (!isNotError && !error.ok) {
      if (error.status == 0) { }
      //    this.showMessage("error", "Unable to process your request, please try again after some time.");
      //  else if(error.status==502) this.showMessage("error", "Something went wrong..Please contact GreenGST team!");
      //  else if(error.status==500) this.showMessage("error", "Something went wrong..Please contact GreenGST team.");
      else if (error.status == 400) {
        var msg = error.json() || '';
        //    this.showMessage("error", msg && msg['error_msg'] ? msg['error_msg'] : "Something went wrong..Please contact GreenGST team.");
      }
      //   else this.showMessage("error", "Something went wrong..Please contact GreenGST team...");
      //  this.pService.done();
      // this.logError(error,url);
    }
    let errMsg: string;
    // TODO error message on screen
    if (error instanceof Response) {
      const body = error.json() || '';
      //  const err = body.error || JSON.stringify(body);
      //  errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  // display success or error message
  showMessage(cssClass, msg) {
    const x = document.getElementById('alert-box');
    if (document.getElementById('paraText') != null) {
      x.removeChild(document.getElementById('paraText'));
    }
    const para = document.createElement('span');
    para.setAttribute('id', 'paraText');
    para.innerHTML = msg;
    let classType = '';
    x.appendChild(para);
    if (cssClass === 'error') {
      classType = 'show-error';
    }
    if (cssClass === 'success') {
      classType = 'show-sucess';
    }
    if (cssClass === 'info') {
      classType = 'show-info';
    }
    if (cssClass === 'downloadMSG') {
      classType = 'show-downloadMSG';
    }
    x.className = classType;
    setTimeout(function () {
      x.className = x.className.replace(classType, '');
    }, 5000);
  }


  // session storage
  createSession(userId, email, token) {
    const pass = 'pass';
    localStorage.setItem('email', email);
    localStorage.setItem('userId', userId);
    localStorage.setItem('token', token);
  }
  setCountryList(countryList){
    localStorage.setItem('countryList',JSON.stringify(countryList));
  }
  setSelectedCountryCode(selectedCountryCode){
    localStorage.setItem('selectedCountryCode',selectedCountryCode);
  }

  setSelectedCountryName(selectedCountryName){
    localStorage.setItem('selectedCountryName',selectedCountryName);
  }
  storeAccessMoudle(modules: any, accessRoutingLink: any) {
    localStorage.setItem('modules', JSON.stringify(modules));
    localStorage.setItem('routingLink', JSON.stringify(Array.from(accessRoutingLink)));
  }

  destorySession() {
    // localStorage.removeItem("email");
    // localStorage.removeItem("name");
    localStorage.clear();
  }

  getSession(): boolean {
    if (localStorage.getItem('email') != null && localStorage.getItem('userId') != null
      && localStorage.getItem('token') != null && localStorage.getItem('modules') != null && localStorage.getItem('routingLink') != null) {
      return true;
    } else {
      return false;
    }
  }

  getUserId() {
    return localStorage.getItem('userId');
  }

  getEmail() {
    return localStorage.getItem('email');
  }

  getCountryList() {
    return JSON.parse(localStorage.getItem('countryList'));
  }

  getSelectedCountryCode(){
    return JSON.parse(localStorage.getItem('selectedCountryCode'));
  }
  getSelectedCountryName(){
    return localStorage.getItem('selectedCountryName');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getModules() {
    return JSON.parse(localStorage.getItem('modules'));
  }

  getRoutingLink() {
    return JSON.parse(localStorage.getItem('routingLink'));
  }

}
